import { useEffect } from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap'
import { Params, useNavigate, useParams } from 'react-router-dom'
import PageHeader from '../../../layouts/PageHeader/PageHeader'
import { useGetWelcomeByIdQuery } from '../../../store/api/welcome/welcome.api'
import Loading from '../../Loading/Loading'

const WelcomeChoiceList = (): JSX.Element => {
  const navigate = useNavigate()
  const { id }: Readonly<Params<string>> = useParams()
  const {
    data: welcome,
    refetch,
    isLoading
  } = useGetWelcomeByIdQuery(id, {
    skip: !id
  })

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  return (
    <div>
      {isLoading && <Loading />}
      <div className='d-flex justify-content-between h-10'>
        <div className=' p-2'>
          <PageHeader
            componentName='Welcome Choice'
            staticName='Welcome Choice'
            activePage=''
            createPageUrl={''}
          />
        </div>
      </div>
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <div>
                <h3 className='card-title mb-1'>Question Choice</h3>
              </div>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>N</th>
                      <th>text</th>
                      <th>tag</th>
                      <th>isCorrect</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {welcome?.choices?.length
                      ? welcome.choices.map((choice, index) => (
                          <tr key={index} className='text-center'>
                            <td>{index + 1}</td>
                            <td>{choice?.text}</td>
                            <td>
                              {choice?.tag?.tag} {choice?.tag?.tagValue}
                            </td>
                            <td>
                              <input type='checkbox' checked={choice.isCorrect} />
                            </td>
                            <td>
                              {' '}
                              <ButtonGroup size='sm' className='flex-nowrap'>
                                <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
                                  <Button onClick={() => navigate(`/welcome/edit/${welcome.id}`)}>
                                    Edit
                                  </Button>
                                </OverlayTrigger>
                              </ButtonGroup>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer>
              <button onClick={() => navigate(-1)} className='btn btn-success mt-1 me-2'>
                Back
              </button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default WelcomeChoiceList
