import { createApi } from '@reduxjs/toolkit/query/react'
import { ISearchParams } from '../../../ui/filter/model'
import { baseQueryWithReauth } from '../apiSlice'
import {
  ICreateUser,
  IUpdateActivateInput,
  IUserById,
  IUserInfo,
  IUserRole,
  TSendExcelResponsOrError,
  TUserDelietRespons
} from './model'
import { IMessageAndStatusCode } from '../admin/model'

export const usersApi = createApi({
  reducerPath: 'users/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getUsers: build.query<IUserInfo, ISearchParams>({
      query: params => ({
        url: '/admin/users',
        params: { ...params }
      })
    }),
    getUserById: build.query<IUserById, string>({
      query: (id: string) => `/admin/users/${id}`
    }),

    getUserRole: build.query<IUserRole[], void>({
      query: () => 'user/roles'
    }),

    createUser: build.mutation<void, ICreateUser>({
      query: (userData: ICreateUser) => ({
        url: 'admin/users',
        method: 'POST',
        body: userData
      })
    }),

    updateUser: build.mutation<void, ICreateUser>({
      query: (userData: ICreateUser) => ({
        url: '/admin/users',
        method: 'PUT',
        body: userData
      })
    }),
    sendExcelFileStudent: build.mutation<TSendExcelResponsOrError, FormData>({
      query: (excelData: FormData) => ({
        url: '/admin/import-students',
        method: 'POST',
        body: excelData
      })
    }),
    sendExcelFileTeacher: build.mutation<TSendExcelResponsOrError, FormData>({
      query: (excelData: FormData) => ({
        url: '/admin/import-teacher',
        method: 'POST',
        body: excelData
      })
    }),
    deleteUser: build.mutation<TUserDelietRespons, string>({
      query: (id: string) => ({
        url: `/admin/users/${id}`,
        method: 'DELETE'
      })
    }),
    BulkDeleteUsers: build.mutation<TUserDelietRespons, number[]>({
      query: (ids: number[]) => ({
        url: '/admin/users/bulkdelete',
        method: 'DELETE',
        body: { ids }
      })
    }),
    updateActivate: build.mutation<IMessageAndStatusCode, IUpdateActivateInput>({
      query: updateData => ({
        url: '/admin/activate',
        body: updateData,
        method: 'PATCH'
      })
    }),
    updateDeactivate: build.mutation<IMessageAndStatusCode, IUpdateActivateInput>({
      query: updateData => ({
        url: '/admin/deactivate',
        body: updateData,
        method: 'PATCH'
      })
    })
  })
})
export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useGetUserRoleQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useSendExcelFileStudentMutation,
  useSendExcelFileTeacherMutation,
  useUpdateActivateMutation,
  useUpdateDeactivateMutation,
  useBulkDeleteUsersMutation
} = usersApi
