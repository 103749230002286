import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  useBulkDeleteUsersMutation,
  useGetUsersQuery,
  useSendExcelFileStudentMutation,
  useSendExcelFileTeacherMutation
} from '../../store/api/users/users.api'
import Pagination from '../../ui/Pagination/Pagination'
import UsersFilters from '../../ui/filter/UsersFilters'
import { searchParamsData } from '../../utils/searchParamsData'
import Loading from '../Loading/Loading'
import UsersTable from './UsersTable/UsersTable'
import { CustomMessageSuccsesOrError } from '../../utils/CustomMessageSuccsesOrError'
import { BASE_URL, DefaultParams, EXCEL_FORMAT, PAGESIZE, USER_ROLE } from '../../utils/constants'
import RemoveModalQuestion from '../../ui/RemoveModalQuestion/RemoveModalQuestion'
import { UseToggleModalHOC } from '../../hooks/toggleModal'
import { toast } from 'react-toastify'

const Users = (): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams(DefaultParams)
  const [roleId, setRoleId] = useState<string>('')
  const [sendExcelStudent] = useSendExcelFileStudentMutation()
  const [sendExcelTeacher] = useSendExcelFileTeacherMutation()
  const [bulkDeleteUsers] = useBulkDeleteUsersMutation()
  const [usersIds, setUsersIds] = useState<number[]>([])
  const { isModalVisible, toggleModal } = UseToggleModalHOC()
  const {
    isLoading,
    data: users,
    refetch
  } = useGetUsersQuery({ ...searchParamsData(searchParams, {}) })

  useEffect(() => {
    refetch()
  }, [searchParams])

  const handleExcelFileChangeStudentOrTeacher = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const selectedFile = event.target.files?.[0]
    const formData = new FormData()

    if (selectedFile) {
      formData.append('file', selectedFile)
      const res =
        roleId === USER_ROLE.Student
          ? await sendExcelStudent(formData)
          : await sendExcelTeacher(formData)
      CustomMessageSuccsesOrError(res)
      refetch()
    }
  }

  useEffect(() => {
    const pathName = location.pathname.split('/')
    const currentRoleId = pathName[pathName.length - 1].toString()
    if (currentRoleId.length === 1) {
      setRoleId(currentRoleId)
      searchParams.set('roleId', currentRoleId)
    } else {
      searchParams.delete('roleId')
      setRoleId('')
    }
  }, [location.pathname])

  const tableName = (roleId: string): string => {
    if (roleId === USER_ROLE.Admin) {
      return 'LeaderShips'
    } else if (roleId === USER_ROLE.Student) {
      return 'Students'
    } else if (roleId === USER_ROLE.Teacher) {
      return 'Teachers'
    } else if (roleId === USER_ROLE.Parent) {
      return 'Parents'
    } else if (roleId === '') {
      return 'Users'
    }
  }

  const handleDeleteUsersByIds = async (): Promise<void> => {
    await bulkDeleteUsers(usersIds)
    toast.success('Users removed successfully')
    toggleModal()
    refetch()
    setUsersIds([])
  }

  const handleCheckboxChange = (id: number): void => {
    if (!usersIds.includes(id)) {
      setUsersIds(prev => [...prev, id])
    } else {
      setUsersIds(prev => prev.filter(resourceId => resourceId !== id))
    }
  }

  const handleSelectAll = (checked: boolean): void => {
    if (checked) {
      const ids = users.data.map(user => user.id)
      setUsersIds(ids)
      return
    }
    setUsersIds([])
  }
  console.log(usersIds, 'usersIds')
  return (
    <div>
      {isLoading && <Loading />}
      <UsersFilters setQuery={setSearchParams} query={searchParams} role={roleId} />
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={10}>
                <h3 className='card-title mb-1'>{tableName(roleId)}</h3>
              </Col>
              <Col className='d-flex'>
                {roleId === USER_ROLE.Student ? (
                  <>
                    <div className='mx-1'>
                      <a
                        download
                        href={`${BASE_URL}admin/export-user-data`}
                        className='btn btn-success'
                        target='_blank'
                      >
                        Export
                      </a>
                    </div>
                    <div>
                      <input
                        ref={inputRef}
                        onChange={e => handleExcelFileChangeStudentOrTeacher(e)}
                        className='d-none'
                        type='file'
                        accept={EXCEL_FORMAT}
                      />
                      <button
                        type='button'
                        onClick={() => inputRef?.current.click()}
                        className='btn btn-success'
                      >
                        <span>
                          <i className='fe fe-log-in'></i>&nbsp;
                        </span>
                        Import Student
                      </button>
                    </div>
                    <button
                      className='btn btn-danger mx-1'
                      onClick={() => usersIds.length && toggleModal()}
                    >
                      Delete Users
                    </button>
                  </>
                ) : null}
                {roleId === USER_ROLE.Teacher ? (
                  <div className='mx-3'>
                    <input
                      ref={inputRef}
                      onChange={e => handleExcelFileChangeStudentOrTeacher(e)}
                      className='d-none'
                      type='file'
                      accept={EXCEL_FORMAT}
                    />
                    <button
                      type='button'
                      onClick={() => inputRef?.current.click()}
                      className='btn btn-success'
                    >
                      <span>
                        <i className='fe fe-log-in'></i>&nbsp;
                      </span>
                      Import Teacher
                    </button>
                  </div>
                ) : null}
                <Button onClick={() => navigate('/users/create')}>Create User</Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      {roleId === USER_ROLE.Student ? (
                        <th>
                          <input
                            type='checkbox'
                            onChange={({ target }) => handleSelectAll(target.checked)}
                            checked={Boolean(usersIds.length)}
                          />
                        </th>
                      ) : null}
                      <th>ID</th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>status</th>
                      <th>package</th>
                      <th>verify</th>
                      <th>create at</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.data.length > 0 &&
                      users?.data.map(user => {
                        return (
                          <UsersTable
                            key={user.id}
                            user={user}
                            refetch={refetch}
                            roleId={roleId}
                            handleCheckboxChange={handleCheckboxChange}
                            usersIds={usersIds}
                          />
                        )
                      })}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Pagination
              setQuery={setSearchParams}
              numPages={Math.ceil(users?.count / PAGESIZE)}
              query={searchParams}
            />
          </Card>
        </Col>
      </Row>
      {isModalVisible ? (
        <RemoveModalQuestion fetchDeleteByIds={handleDeleteUsersByIds} toggleModal={toggleModal} />
      ) : null}
    </div>
  )
}

export default Users
